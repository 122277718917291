import axios from "axios";
import { notification } from "antd";
import { V2_RECORD_ID_API } from "src/constants/api-urls";
import cacheService from "./cache.service";
import { authService } from "./index";
import { HttpError } from "src/shared/HttpError";
import { GENERIC_ERRORS } from "src/constants/errors";

export class FMXservice {
  async getV2RecordIdFromV1ClaimId(clientId: string | null, claimId: any) {
    const url = new URL(V2_RECORD_ID_API);

    url.searchParams.set("claimId", claimId);
    let accountId = cacheService.getItemsSync("accountId");

    try {
      let accessToken = await authService.getFirebaseToken();
      if (accessToken) {
        let headers: any = {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
          "x-oj-client-id": clientId,
        };
        if (accountId) {
          headers = {
            ...headers,
            "x-oj-client-id": accountId,
          };
        }
        const v2RecordResponse: any = await axios(url.href, {
          method: "get",
          headers,
        });
        if (v2RecordResponse.data) {
          return v2RecordResponse.data;
        }
      }
      return null;
    } catch (error) {
      if (error instanceof HttpError) {
        notification.error({ message: error.message });
      } else {
        notification.error({ message: GENERIC_ERRORS.SOMETHING_WRONG });
      }
    }
  }
}

const fmxService = new FMXservice();
export default fmxService;
